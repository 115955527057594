import styled from "@emotion/styled";
import { Container, Link } from "@mui/material";
import React from "react";
import VideoBg from "../../../../assets/images/comunityVideo.mp4";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  DataImage,
  SectionHeading,
  StyledSpan,
} from "../../../Styles/style";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 54px);
  width: 100%;
  position: relative;
  z-index: 0;
  ::after {
    content: "";
    background-color: #000000be;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 0;
  }
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh - 54px);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const Community = () => {
  return (
    <>
      <SectionWraper id="home">
        <Tiker />
        <AbsoluiteBackgroundVideo src={VideoBg} muted autoPlay loop />
        <InnerContinerWraper>
          <CustomGrid container jc="center">
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="center"
              g="30px"
            >
              <SectionHeading>
                Join the community <br />
                and kill <StyledSpan>mickey</StyledSpan> <br />
                together
              </SectionHeading>
              <div style={{ display: "flex", gap: "20px" }}>
                <Link
                  sx={{
                    background: "#FF1036",
                    border: "1px solid #000",
                    display: "grid",
                    placeItems: "center",
                    padding: "5px",
                    zIndex: "1",
                  }}
                  href="https://t.me/MickeyKillerETH"
                  target="blank"
                >
                  <DataImage src={tg} mw="50px" />
                </Link>
                <Link
                  sx={{
                    background: "#FF1036",
                    border: "1px solid #000",
                    display: "grid",
                    placeItems: "center",
                    padding: "5px",
                    zIndex: "1",
                  }}
                  href="https://twitter.com/MickeyKillerETH"
                  target="blank"
                >
                  <DataImage src={x} mw="50px" />
                </Link>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Community;
