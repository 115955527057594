import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import VideoBg from "../../../../assets/images/tokenomicsVideo.mp4";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 54px);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh - 54px);
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: start;
  @media (max-width: 599px) {
  }
`;

const Tokenomics = () => {
  const [contractAddress, setcontractAddress] = React.useState("TBA");
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setIsAlertVisible(true);
    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };
  return (
    <>
      <SectionWraper id="home">
        <Tiker />
        <AbsoluiteBackgroundVideo src={VideoBg} muted autoPlay loop />
        <InnerContinerWraper sx={{ paddingTop: "100px" }}>
          <CustomGrid container fd="column" ai="start" g="20px">
            <CustomGrid
              container
              item
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="start"
              g="20px"
              p="20px 20px 40px !important"
              sx={{ background: "#000000be", zIndex: 1,position:"relative" }}
            >
              {isAlertVisible && (
                <div className="alert-container">
                  +{" "}
                  <div style={{ transition: "1s" }} className="alert-inner">
                    <Paragraph fs="20px" fs599="14px">
                      Contract Address Copied
                    </Paragraph>
                  </div>
                  +{" "}
                </div>
              )}
              <SectionHeading ta="left" c="#FF1036">
                Tokenomics
              </SectionHeading>
              <CustomGrid item xs={12} ai="center" jc="space-between">
                <Paragraph fs="20px" fs599="18px">
                  Lp:{" "}
                </Paragraph>
                <Paragraph fs="20px" fs599="18px">
                🔒Locked{" "}
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={12} ai="center" jc="space-between">
                <Paragraph fs="20px" fs599="18px">
                Renounced:
                </Paragraph>
                <Paragraph fs="20px" fs599="18px">
                  TBA{" "}
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={12} ai="center" jc="space-between">
                <Paragraph fs="20px" fs599="18px">
                  Tax:{" "}
                </Paragraph>
                <Paragraph fs="20px" fs599="18px">
                  💰0
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={12} ai="center" jc="space-between">
                <Paragraph fs="20px" fs599="18px">
                  Supply:
                </Paragraph>
                <Paragraph fs="20px" fs599="18px">
                  TBA{" "}
                </Paragraph>
              </CustomGrid>
            </CustomGrid>
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={6}
              ai="center"
              jc="space-between"
              p="10px 20px"
              sx={{ background: "#FF1036", zIndex: 1 }}
            >
              <Paragraph fs="20px" fs599="18px">
                CA:
              </Paragraph>
              <Paragraph fs="20px" fs599="18px">
                {contractAddress}
                <span onClick={handleCopy} style={{ cursor: "pointer" }}>
                  📋
                </span>
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Tokenomics;
