import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import roadmapBg from "../../../../assets/images/roadmapBg.png";
import { CustomGrid, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 54px);
  width: 100%;
  position: relative;
  background-image: url(${roadmapBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height:calc(100vh - 54px);
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: end;
  @media (max-width: 599px) {
  }
`;

const RoadMap = () => {
  return (
    <>
      <SectionWraper  id="about">
      <Tiker/>
        <InnerContinerWraper sx={{ paddingY: "100px" }}>
          <CustomGrid container jc="center">
            <CustomGrid container item xs={12} fd="column" ai="start" g="20px">
              <CustomGrid item xs={12} jc="start">
                <SectionHeading c="#FF1036" ta="left">
                  Roadmap
                </SectionHeading>
              </CustomGrid>
              <CustomGrid container item xs={12} ai="start" jc="start" g="30px 0">
                <CustomGrid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  fd="column"
                  ai="start"
                  g="5px"
                >
                  <Paragraph ta="left">Phase 1</Paragraph>
                  <Paragraph fs="20px" fs599="18px" ta="left">
                    📉Sell mickeys And
                  </Paragraph>
                  <Paragraph fs="20px" fs599="18px" ta="left">
                    📈Buy pete's.
                  </Paragraph>
                </CustomGrid>
                <CustomGrid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  fd="column"
                  ai="start"
                  g="5px"
                >
                  <Paragraph ta="left">Phase 2</Paragraph>
                  <Paragraph fs="20px" fs599="18px" ta="left">
                    🚀 Launch Party
                  </Paragraph>
                  <Paragraph fs="20px" fs599="18px" ta="left">
                    🎉 Community Building
                  </Paragraph>
                </CustomGrid>
                <CustomGrid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  fd="column"
                  ai="start"
                  g="5px"
                >
                  <Paragraph ta="left">Phase 3</Paragraph>
                  <Paragraph fs="20px" fs599="18px" ta="left">
                    💧 Liquidity Boost
                  </Paragraph>
                  <Paragraph fs="20px" fs599="18px" ta="left">
                    🔄 Exchange Listing
                  </Paragraph>
                </CustomGrid>
              </CustomGrid>
              <CustomGrid item xs={12} jc="start">
                <CustomButton>Buy Now</CustomButton>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default RoadMap;
