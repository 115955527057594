import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import aboutBg from "../../../../assets/images/aboutBg.png";
import {
  CustomGrid,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: 40vh;
  background-color: #1c1c1c;
  width: 100%;
  position: relative;
  z-index: 0;
  ::after {
    content: "";
    background-image: url(${aboutBg});
    background-size: contain;
    background-position: center;
    background-repeat: repeat;
    filter: grayscale(1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: -1;
  }
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(40vh - 0px);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const Disclaimer = () => {
  return (
    <>
      <SectionWraper  id="disclaimer">
        <InnerContinerWraper sx={{ paddingY: "50px" }}>
          <CustomGrid container jc="center">
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={7}
              fd="column"
              ai="center"
              g="30px"
            >
              <SectionHeading c="#FF1036">Disclaimer</SectionHeading>
              <Paragraph>
                Pete Token is all about spreading good vibes and laughter. The
                "Mickey Killer" tagline is just Pete flexing his cartoon muscles
                – no harm intended to our Disney pals. Pete Token is not
                Disney-approved, but it's definitely meme-approved.
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Disclaimer;
