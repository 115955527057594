import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";
import Tokenomics from "../../Components/Sections/S3-Tokenomics";
import RoadMap from "../../Components/Sections/S4-Roadmap";
import Community from "../../Components/Sections/S5-Community";
import Disclaimer from "../../Components/Sections/S6-Disclaimer";

const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <HeroSection />
      <AboutSection/>
      <Tokenomics/>
      <RoadMap/>
      <Community/>
      <Disclaimer/>
    </PageWrapper>
  );
};

export default MainPage;
