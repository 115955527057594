import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import aboutBg from "../../../../assets/images/aboutBg.png";
import aboutImage from "../../../../assets/images/aboutImage.png";
import aboutBottom from "../../../../assets/images/aboutBottom.png";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 54px);
  background-color: #1c1c1c;
  width: 100%;
  position: relative;
  z-index: 0;
  ::after {
    content: "";
    background-image: url(${aboutBg});
    background-size: contain;
    background-position: center;
    background-repeat: repeat;
    filter: grayscale(1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: -1;
  }
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh - 54px);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const AboutSection = () => {
  return (
    <>
      <SectionWraper id="about">
        <Tiker />
        <InnerContinerWraper sx={{ paddingY: "100px" }}>
          <CustomGrid container jc="center">
            <CustomGrid container item xs={12} fd="column" ai="center" g="30px">
              <CustomGrid
                item
                xs={12}
                sm={9}
                md={7}
                fd="column"
                ai="center"
                g="30px"
              >
                <SectionHeading c="#FF1036">The Story of pETE</SectionHeading>
                <Paragraph>
                  Pete, known as the 'Mickey Killer,' has earned his fearsome
                  reputation as the most violent and merciless killer on the
                  blockchain. Fueled by a burning desire for revenge, Pete is
                  incensed by the sight of his rival, Mickey, dominating the
                  Ethereum blockchain. This perceived humiliation has triggered
                  a vengeful spree, with Pete determined to dethrone the puny
                  mouse and obliterate him once and for all. No matter how far
                  Mickey tries to run, Pete's relentless pursuit ensures that he
                  will be found; by any means necessary!
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={12} sm={9} jc="center">
                <DataImage src={aboutImage} />
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
        <AbsoluiteImage
          mw="95%"
          style={{
            bottom: "0",
            right: "2%",
          }}
          src={aboutBottom}
        />
      </SectionWraper>
    </>
  );
};

export default AboutSection;
