import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  SectionHeading,
  StyledSpan,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import VideoBg from "../../../../assets/images/heroVideo.mp4";
import Tiker from "../../marquetag";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100dvh - 54px);
  width: 100%;
  position: relative;
  z-index: 0;
  ::after {
    content: "";
    background-color: #000000be;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 0;
  }
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 54px);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const HeroSection = () => {
  return (
    <>
      <SectionWraper id="home">
        <Tiker />
        <AbsoluiteBackgroundVideo src={VideoBg} muted autoPlay loop />
        <InnerContinerWraper>
          <CustomGrid container jc="center">
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="center"
              g="20px"
            >
              <SectionHeading>
                PETE <StyledSpan>mickey</StyledSpan> <br /> KILLER IS HERE!
              </SectionHeading>
              <Paragraph lh="1.5" m="0 20px">
                Introducing Pete, The Most Violent and Merciless Killer On The
                Blockchain - Pete's Bitter Rival Mickey Claims His Revenge...
              </Paragraph>
              <div style={{ display: "flex", gap: "20px" }}>
                <CustomButton>Trade Now</CustomButton>
                <CustomButton bgc="#fff" hbgc="#FF1036" c="#FF1036" hc="#fff">
                  View Chart
                </CustomButton>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default HeroSection;
