import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const CustomBtn = styled(Button)`
  background: ${(props) => (props.bgc ? props.bgc : "#FF1036")};
  font-size: ${(props) => (props.fs ? props.fs : "20px")};
  border-radius: 0px;
  min-width: ${(props) => (props.w ? props.w : "150px")};
  font-family: ${(props) => (props.fm ? props.fm : "RifficFreeBold")};
  margin: ${(props) => (props.m ? props.m : "0")};
  padding: ${(props) => (props.p ? props.p : "8px 20px")};
  color: ${(props) => (props.c ? props.c : "#fff")};
  /* border: ${(props) => (props.b ? props.b : "1px solid #FCD5A5")}; */
  transition: .5s;
  text-transform: none;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: #000;
  cursor: ${(props) => (props.cur ? props.cur : "pointer")};
  z-index: 1;
  :hover {
    color: ${(props) => (props.hc ? props.hc : "#FF1036")};
    background: ${(props) => (props.hbgc ? props.hbgc : "#fff")};
  }
  @media (max-width: 599px) {
    padding: ${(props) => (props.p ? props.p : "8px 20px")};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "16px")};
  }
`;

const CustomButton = (props) => {
  const { children } = props;
  return (
    <CustomBtn variant="filled" {...props}>
      {children}
    </CustomBtn>
  );
};

export default CustomButton;
